<script>
import axios from 'axios';

export default {
  name: 'HomeView',
  components: {
    //axios,
  },
  data() {
    return {
      pages: [],
    }
  },
  mounted() {
    axios.get('http://content.m39a.de:1337/api/landingpages')
      .then((data) => {
        this.pages = data.data.data
      })
  },
}
</script>

<template>

  <header class="navigation">
    <div class="navbar-container rounded col-12 col-md-6 position-fixed bg-white py-1 px-2 shadow-lg">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#"><span>M</span>39A</a>
          <button class="navbar-toggler border-0 text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="ti-menu"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="ms-auto text-md-end navbar-nav mb-2 mb-lg-0">
              <li v-for="page in pages" class="nav-item" :key="page.id">
                <RouterLink class="nav-link text-dark" :to="{path: '/page/' + page.attributes.Slug}">{{page.attributes.Title}}</RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>

  <div class="main-wrapper">
    <router-view />
  </div>

  <section class="mt-70 position-relative">
    <div class="container">
      <div class="cta-block-2 bg-gray p-5 rounded border-1">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-7">
            <span class="text-color">Gemeinsam ans Ziel</span>
            <h2 class="mt-2 mb-4 mb-lg-0">Nehmen Sie jetzt Kontakt auf und stellen Sie uns Ihr Projekt vor.</h2>
          </div>
          <div class="col-lg-4 text-right">
            <a href="contact.html" class="btn btn-main btn-round-full float-lg-end ">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="footer section">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="widget">
            <h4 class="text-capitalize mb-4">Service</h4>

            <ul class="list-unstyled footer-menu lh-35">
              <li><a href="#">Impressum</a></li>
              <li><a href="#">Datenschutz</a></li>
              <li><a href="#">FAQ</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="widget">
            <h4 class="text-capitalize mb-4">Unternehmen</h4>

            <ul class="list-unstyled footer-menu lh-35">
              <li><a href="#">About</a></li>
              <li><a href="#">Leistungen</a></li>
              <li><a href="#">Team</a></li>
              <li><a href="#">Kontakt</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="widget">
          </div>
        </div>

        <div class="col-lg-3 ms-auto col-sm-6">
          <div class="widget">
            <div class="logo mb-4">
              <h4>M39A</h4>
            </div>
            <a href="mailto:hallo@m39a.de" class="h6">hallo@m39a.de</a>
            <a href="tel:+4920237323600"><span class="text-color h4">+49 202 37 32 36 00</span></a>
          </div>
        </div>
      </div>
      
      <div class="footer-btm pt-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="copyright">
              &copy; 2022 <span class="text-color">M39A</span>
            </div>
          </div>
          <div class="col-lg-6 text-start text-lg-end">
            <ul class="list-inline footer-socials">
              <li class="list-inline-item"><a href="https://www.facebook.com/themefisher"><i class="ti-facebook me-2"></i>Facebook</a></li>
              <li class="list-inline-item"><a href="https://twitter.com/themefisher"><i class="ti-twitter me-2"></i>Twitter</a></li>
              <li class="list-inline-item"><a href="https://www.pinterest.com/themefisher/"><i class="ti-linkedin me-2 "></i>Linkedin</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style></style>
