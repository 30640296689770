<script>
import axios from 'axios'
import ContentBlocks from '../components/ContentBlocks.vue'

export default {
  name: 'PageView',
  components: {
    ContentBlocks,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      contentBlocks: [],
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      // previousParams
      (toParams) => {
        this.slug = toParams.slug
        console.log(this.slug);
        this.getData()
      },
    )
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get(
          'http://content.m39a.de:1337/api/landingpages?filters[slug][$eq]=' +
            this.slug,
        )
        .then((data) => {
          this.id = data.data.data[0].id
          axios
            .get(
              'http://content.m39a.de:1337/api/landingpages/' +
                this.id +
                '?populate=deep',
            )
            .then((data) => {
              this.contentBlocks = data.data.data.attributes.ContentBlocks
            })
        })
      console.log(this.contentBlocks)
    },
  },
}
</script>

<template>
  <main>
    <div v-for="contenblock in contentBlocks" :key="contenblock">
      <ContentBlocks :data="{contenblock}" />
    </div>
  </main>
</template>
