<script>
export default {
  name: 'ContentBlocks',
  props: {
    data: []
  }
}
</script>

<template>

  <!-- <pre>{{data}}</pre> -->

  <!-- Hero Slider -->
  <section v-if="data.contenblock.__component === 'image-text.hero'" class="slider" :style="{'background-image':'url(http://content.m39a.de:1337'+data.contenblock.Image.data.attributes.formats.large.url+')'}">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <div class="block">
            <span class="d-block mb-3 text-white text-capitalize">{{data.contenblock.h2}}</span>
            <h1 class="animated fadeInUp mb-5">{{data.contenblock.H1}}</h1>
            <a href="#" target="_blank" class="btn btn-main animated fadeInUp btn-round-full" >{{data.contenblock.buttontext}}<i class="btn-icon fa fa-angle-right ms-2"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Hero Slider end -->

</template>

<style>
</style>
